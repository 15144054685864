<template>
  <div class="l-file-upload">
    <div
      class="l-file-upload__dragzone"
      @dragover.prevent
      @click="clickFileInput"
      @drop.prevent="dropped"
    >
      <p
        class="l-file-upload__dragzone-text"
        :style="{fontWeight: fileName ? '700':'400'}"
      >
        {{ `${fileName ? fileName : 'Drop Files Here to Upload' }` }}
      </p>
      <input
        v-show="false"
        ref="refLFileUploadInput"
        :accept="formattedAcceptProps"
        type="file"
        @input="fileInputChanged"
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      fileName: null,
    }
  },
  computed: {
    formattedAcceptProps() {
      return this.acceptExtensionArray.join(',')
    },
  },
  methods: {
    dropped(e) {
      if (!e.dataTransfer.files[0]) return
      this.fileName = e.dataTransfer.files[0].name
      this.$emit('input', this.onlyAcceptableFiles(e.dataTransfer.files[0]))
    },
    onlyAcceptableFiles(filesArray) {
      // todo add check for it
      return filesArray
    },
    fileInputChanged(e) {
      if (!e.target.files[0]) return
      this.fileName = e.target.files[0].name
      this.$emit('input', e.target.files[0])
    },
    clearInputValue() {
      this.$refs.refLFileUploadInput.value = null
    },
    clickFileInput() {
      this.$refs.refLFileUploadInput.click()
    },
  },
}
</script>
<style lang="scss">
.l-file-upload {

  &__dragzone {
    height: 116px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #DDE3E7;
    border-radius: 5px;
    color: #ADADAD;
    cursor: pointer;

    &:hover {
      border-color: #646464;
      transition: all 0.3s ease;
    }

    &:hover &-text {
        transition: all 0.3s ease;
        color: #646464;
      }
  }

  &__dragzone-text {
    margin: auto;
  }
}
</style>
