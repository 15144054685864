import {
  CHECKBOX_LEF,
  FLAT_PICKER,
  L_CURRENCY_MASK_INPUT,
  L_TAGS_INPUT,
  SELECT_INPUT,
  TEXT_INPUT,
  TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'
import store from '@/store'
import { reactive } from '@vue/composition-api'
import { can } from '@core/libs/acl/utils'
import axiosIns from '@/libs/axios'
import { stringify } from 'qs'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import LTable from '@/views/components/LTable/LTable.vue'
import Actions from '@/views/components/Actions.vue'
import ImportCSV from '@/views/components/Modals/ImportCSV.vue'
import TableActions from '@/views/main/inventory/inventory-view/components/TableActions.vue'
import ListTableActionsNonSerialized
from '@/views/main/inventory/inventory-view/components/ListTableActionsNonSerialized.vue'
import ExportCSV from '@/views/components/Modals/ExportCSV.vue'
import Printer from '@/views/components/Printer/Printer.vue'

export default function config() {
  const ACCESS_ABILITY_INVENTORY = { action: 'change', subject: 'Inventory' }
  // Table Handlers
  const tableColumnsCatalog = reactive([
    {
      key: 'actions',
      label: '',
      thStyle: { width: can(ACCESS_ABILITY_INVENTORY.action, ACCESS_ABILITY_INVENTORY.subject) ? '12%' : '0%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '40%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '18%' },
    },
    {
      key: 'inventory_item_current_warehouse_count',
      label: 'Stock',
    },
    {
      key: 'inventory_item_other_warehouse_count',
      label: 'Other Loc.',
    },
    {
      key: 'inventory_item_count',
      label: 'Total',
    },
  ])

  const tableColumnsAssets = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: can(ACCESS_ABILITY_INVENTORY.action, ACCESS_ABILITY_INVENTORY.subject) ? '17%' : '0%' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
      thStyle: { width: '10%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '15%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '43%' },
    },
    {
      key: 'state',
      label: 'Asset State',
      thStyle: { width: '15%' },
    },
  ]

  const tableColumnsInventoryCalendar = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '50px' },
    },
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'default_contact',
      label: 'TOTAL',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'brand.name',
      label: 'Brand',
    },
  ]

  const tableTrashListColumnsInventoryItems = [

    {
      key: 'actions',
      label: '',
      thStyle: { width: '210px' },
    },
    {
      key: 'warehouse',
      label: 'Warehouse',
    },
    {
      key: 'locations',
      label: 'Pick Location',
      thStyle: { textAlign: 'center' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
    },
    {
      key: 'sn_last_digits',
      label: 'SN Last Digits',
    },
    {
      key: 'active_since',
      label: 'Active Since',
    },
    {
      key: 'current_state',
      label: 'Current state',
    },
  ]

  const tableColumnsInventoryItems = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '210px' },
    },
    {
      key: 'warehouse',
      label: 'Warehouse',
    },
    {
      key: 'locations',
      label: 'Pick Location',
      thStyle: { textAlign: 'center' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
    },
    {
      key: 'sn_last_digits',
      label: 'SN Last Digits',
    },
    {
      key: 'active_since',
      label: 'Active Since',
    },
    {
      key: 'current_state',
      label: 'Current state',
    },

  ]

  const tableColumnsInventoryItemsNonSerialized = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '15%' },
    },
    {
      key: 'asset_id',
      label: 'Inventory Qty',
      thStyle: { width: '15%' },
    },
    {
      key: 'locations',
      label: 'Pick Location',
      thStyle: { width: '15%' },
    },
    {
      key: 'state',
      label: 'Current state',
      thStyle: { width: '65%' },
    },
  ]

  const typeOfInfo = {
    ALLOCATED: 'allocated',
    AVAILABLE: 'available',
  }

  const fields = {
    purchase_order_number: {
      type: TEXT_INPUT,
      label: 'Purchase Order Number',
      placeholder: 'Type...',
      rules: 'required',

    },
    cost: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Cost',
      rules: 'required',
    },
    sale_price: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Sale Price',
      rules: '',
    },
    active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
    inspected: {
      type: CHECKBOX_LEF,
      label: 'Inspected',
    },
    tested: {
      type: CHECKBOX_LEF,
      label: 'Tested',
    },
    allowed_to_sell: {
      type: CHECKBOX_LEF,
      label: 'Allowed to sell',
      rules: '',
    },
    date_of_purchase: {
      type: FLAT_PICKER,
      label: 'Date of Purchase',
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    invoice_number: {
      type: TEXT_INPUT,
      label: 'Invoice Number',
      placeholder: 'Type...',
    },
    available_start_date: {
      type: FLAT_PICKER,
      label: 'Available Start Date',
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    tags: {
      type: L_TAGS_INPUT,
      label: 'Tags',
      store: 'tagsList',
    },
    comments: {
      type: TEXTAREA_INPUT,
      label: 'Comments',
      placeholder: 'Write notes here...',
    },
  }

  const fieldsNonSerialized = {
    purchase_order_number: {
      type: TEXT_INPUT,
      label: 'Purchase Order Number',
      placeholder: 'Type...',
      rules: 'required',

    },
    cost: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Cost',
      rules: 'required',
      placeholder: '$1.599.0',
    },
    active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
    inspected: {
      type: CHECKBOX_LEF,
      label: 'Inspected',
    },
    tested: {
      type: CHECKBOX_LEF,
      label: 'Tested',
    },
    date_of_purchase: {
      type: FLAT_PICKER,
      label: 'Date of Purchase',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    available_start_date: {
      type: FLAT_PICKER,
      label: 'Available Start Date',
      hasOperationHours: true,
      rules: 'required',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    invoice_number: {
      type: TEXT_INPUT,
      label: 'Invoice Number',
      placeholder: 'Type...',
    },
    tags: {
      type: L_TAGS_INPUT,
      label: 'Tags',
      store: 'tagsList',
    },
    comments: {
      type: TEXT_INPUT,
      label: 'Comments',
      placeholder: 'Type...',
    },
  }

  const fieldsNonSerializedAmount = {
    // name: {
    //   type: TEXT_INPUT,
    //   label: 'Name',
    //   rules: 'required',
    //   placeholder: 'Type...',
    // },
    serial_number: {
      type: TEXT_INPUT,
      label: 'Serial Number',
      rules: 'required',
      placeholder: 'Type...',
    },
    sale_price: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Sale Price',
      rules: '',
    },
    condition_id: {
      type: SELECT_INPUT,
      label: 'Condition',
      store: 'assetConditionList',
    },
    allowed_to_sell: {
      type: CHECKBOX_LEF,
      label: 'Allowed to sell',
      rules: '',
    },
  }

  const updateFields = {
    serial_number: {
      type: TEXT_INPUT,
      label: 'Serial Number',
      rules: '',
      placeholder: 'Type...',
    },
    cost: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Cost',
      placeholder: 'Type...',
      rules: 'required',
    },
    sale_price: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Sale Price',
      placeholder: '$1,234.00',

    },
    active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
    inspected: {
      type: CHECKBOX_LEF,
      label: 'Inspected',
      rules: 'required',

    },
    tested: {
      type: CHECKBOX_LEF,
      label: 'Tested',
    },
    tags: {
      type: L_TAGS_INPUT,
      label: 'Tags',
      store: 'tagsList',
    },
    comments: {
      type: TEXT_INPUT,
      label: 'Comments',
      placeholder: 'Type...',
    },
    attachments: {
      type: null,
      label: '',
    },
    available_start_date: {
      type: FLAT_PICKER,
      label: 'Available Start Date',
      hasOperationHours: true,
      rules: 'required',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    condition_id: {
      type: SELECT_INPUT,
      label: 'Condition',
      store: 'assetConditionList',
      rules: 'required',

    },
    allowed_to_sell: {
      type: CHECKBOX_LEF,
      label: 'Allowed to Sell ',
      rules: '',
    },
  }

  const locationFields = {
    serial_number: {
      type: TEXT_INPUT,
      placeholder: 'Serial Number',
      rules: '',
    },
    asset_number: {
      type: TEXT_INPUT,
      placeholder: 'Asset Number',
      rules: '',
    },
    available_from: {
      type: FLAT_PICKER,
      placeholder: 'Available Date Start',
      hasOperationHours: true,
      rules: 'required',
      class: 'bg-date',
    },
  }

  const blankLocation = {
    serial_number: null,
    sale_price: null,
    condition_id: null,
    allowed_to_sell: false,
  }

  const inventoryItemsSortTabs = [
    {
      id: 1,
      name: store.state.app.warehouse.label,
      // query: `${store.state.app.warehouse.id}`,
      query: {
        location: `${store.state.app.warehouse.id}`,
        current_location: `${store.state.app.warehouse.id}`,
      },
    },
    {
      id: 2,
      name: 'Other Locs.',
      // query: 'other',
      query: {
        location: 'other',
        current_location: `${store.state.app.warehouse.id}`,
      },
    },
    {
      id: 3,
      name: 'All',
      query: '/',
    },
  ]

  const inventoryProductListTab = [
    {
      id: 1,
      title: 'Grouped',
      name: 'home-inventory-list-catalog',
      query: 'catalog',
    },
    {
      id: 2,
      title: 'Ungrouped',
      name: 'home-inventory-list-assets',
      query: 'assets',
    },
  ]

  const AMOUNT_ADD_MIN_LIMIT = '0'
  const AMOUNT_ADD_MAX_LIMIT = '99999'

  const formattedDate = () => {
    const date = {
      fullYear: new Date().getFullYear(),
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
    }
    const { fullYear, day, month } = date
    const secondMonth = month < 10 ? `0${month}` : month
    const secondDay = day < 10 ? `0${day}` : day
    return [`${fullYear}`, `${secondMonth}`, `${secondDay}`].join('')
  }
  const download = (res, { export_type, title }) => {
    const fileBlob = window.URL.createObjectURL(new Blob([res.data]))
    const fileLink = document.createElement('a')
    fileLink.href = fileBlob
    // eslint-disable-next-line camelcase
    const fileName = export_type || title.toLowerCase().split(' ').join('_')
    fileLink.setAttribute('download', `${fileName}_${formattedDate()}.csv`)
    fileLink.setAttribute('id', 'test-download')
    // fileLink.setAttribute('target', '_blank')
    document.body.appendChild(fileLink)
    fileLink.click()
  }
  const exportDataRequest = (path, params, MODULE_NAME, ctx) => {
    axiosIns({
      url: path,
      params,
      // eslint-disable-next-line no-shadow
      paramsSerializer: params => stringify(params),
      method: 'GET',
      responseType: 'blob',
    }).then(res => {
      download(res, params)
    }).catch(err => {
      console.log(err)
      ctx.$toast({
        component: ToastificationContentVue,
        props: {
          title: i18n.t('Error Uploading This file', {
            module: MODULE_NAME,
          }),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }).finally(() => {
      // eslint-disable-next-line no-param-reassign
      ctx.isExporting = false
      ctx.hide()
    })
  }
  const addToExistingQuoteModelTable = [
    {
      key: 'radio',
      label: 'Select',
      thStyle: { width: '10px' },
    },
    {
      key: 'order_id',
      label: 'Order №',
      thStyle: { width: '10px' },
    },
    {
      key: 'order_date',
      label: 'Order Date',
      thStyle: { width: '10px' },
    },
    {
      key: 'customer',
      label: 'Customer Name',
      thStyle: { width: '10px' },
    },
    // {
    //   key: 'order_id',
    //   label: 'Order id',
    //   thStyle: { width: '10px' },
    // },
  ]
  return {
    components: {
      Printer,
      ExportCSV,
      ListTableActionsNonSerialized,
      TableActions,
      ImportCSV,
      Actions,
      LTable,
    },
    fields,
    typeOfInfo,
    blankLocation,
    locationFields,
    exportDataRequest,
    updateFields,
    tableColumnsAssets,
    tableColumnsCatalog,
    fieldsNonSerialized,
    AMOUNT_ADD_MIN_LIMIT,
    AMOUNT_ADD_MAX_LIMIT,
    inventoryItemsSortTabs,
    inventoryProductListTab,
    fieldsNonSerializedAmount,
    tableColumnsInventoryItems,
    tableColumnsInventoryCalendar,
    ACCESS_ABILITY_INVENTORY,
    tableColumnsInventoryItemsNonSerialized,
    tableTrashListColumnsInventoryItems,
    addToExistingQuoteModelTable,
  }
}
