<template>
  <div>
    <div
      class="d-flex"
      style="gap: 8px"
    >
      <vue-autosuggest
        id="supplier-name"
        ref="autocomplete"
        v-model="searchQuery"
        :suggestions="suggestions"
        :input-props="getSearchInputProps()"
        class="flex-fill"
        :render-suggestion="renderSuggestion"
        :get-suggestion-value="getSuggestionValue"
        @input="fetchResults"
        @selected="selectHandler"
      >
        <template slot="after-input">
          <b-spinner
            v-if="isSearching"
            small
            class="spinner"
            variant="secondary"
          />
        </template>
      </vue-autosuggest>
      <b-button
        class="btn-mini"
        variant="success"
        style="min-width: 36px; max-width: 36px; height: 36px"
      >
        <feather-icon
          icon="LSearchIcon"
          size="16"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import { debounce } from '@core/utils/utils'
import '@core/scss/vue/libs/vue-autosuggest.scss'

import {
  BButton, BSpinner,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'SelectSupplier',
  components: {
    BButton,
    BSpinner,
    VueAutosuggest,
  },
  data() {
    return {
      searchQuery: '',
      suggestions: [],
      isSearching: false,
    }
  },
  computed: {
    supplierForm() {
      return this.$store.state[this.MODULE_NAME].supplierForm
    },
  },
  methods: {
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'product-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'product-search',
      }
      return {
        ...defaultInputProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },

    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.name
    },
    fetchResults(searchQuery) {
      this.search(searchQuery, this)
    },
    selectHandler(suggest) {
      if (this.supplierForm.supplier.length) {
        this.supplierForm.supplier = []
        this.supplierForm.supplier.push(suggest.item)
      } else {
        this.supplierForm.supplier.push(suggest.item)
      }
      this.$emit('selectHandler')
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 50),

    getSelectOptions(params) {
      this.suggestions = []
      if (params && params.length > 2) {
        this.isSearching = true

        this.$store.dispatch(`${this.MODULE_NAME_INVENTORY}/getSuppliers`, {
          f: {
            cust_name_dba: params,
            allow_to_purchase_from_supplier: '1',
          },
        }).then(({ data }) => {
          const mappedData = data.data.data.map(item => ({
            ...item,
            name: item.cust_name_dba ? item.cust_name_dba : '---',
          }))
          this.suggestions = [{ data: mappedData }]
        }).finally(() => {
          this.isSearching = false
        })
      }
    },
  },
  setup() {
    const MODULE_NAME_INVENTORY = 'inventories'
    const MODULE_NAME = 'quote'

    return {
      MODULE_NAME,
      MODULE_NAME_INVENTORY,
    }
  },
}
</script>
<style lang="scss">
#supplier-name {
  position: relative;
  & .form-control {

  }

  & .spinner {
    position: absolute;
    top: 12px;
    right: 8px;
  }
}
</style>
