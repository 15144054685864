<template>
  <div>
    <div class="d-flex justify-content-between my-2">
      <h4 class="font-weight-bolder">
        {{ $t('Suppliers for this product') }}
      </h4>
      <add-suppliers-for-product :fetch-data="fetchData" />
    </div>

    <div v-if="isLoading">
      <table-skeleton />
    </div>
    <div
      v-else
    >
      <b-table-simple
        class="suppliers-for-this-product-table"
        responsive
        striped
      >
        <b-thead>
          <b-tr>
            <b-th
              v-for="{key, label, thStyle,} in tableColumnsCatalog"
              :key="key"
              :style="thStyle"
            >
              {{ label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="suppliers.data.supplier.length">
          <b-tr
            v-for="(suplier, index) in suppliers.data.supplier"
            :key="index"
          >
            <b-th>
              <div class="d-flex pl-1">
                <b-form-checkbox @change="setSuppliersToRequestForQuote(suplier)" />
              </div>
            </b-th>

            <b-th>
              <div>
                {{ getProperty('companyName', suplier) }}
              </div>
            </b-th>
            <b-th>
              <div
                v-b-tooltip.noninteractive.hover
                class="name-cell"
                :title="getProperty('fullName', suplier)"
              >
                {{ getProperty('fullName', suplier) }}
              </div>
            </b-th>
            <b-th>
              <div> &mdash; </div>
            </b-th>
            <b-th>
              <div> &mdash; </div>
            </b-th>
            <b-th>
              <div> &mdash; </div>
            </b-th>
            <b-th>
              <div> &mdash; </div>
            </b-th>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div
        v-if="isThereAnySuppliers"
        class="suppliers-for-this-product-table__no-data d-flex justify-content-center align-items-center border"
      >
        {{ $t('No Suppliers') }}
      </div>
    </div>
    <small
      v-if="isSuppliersForThisProductIsEmpty"
      class="text-danger"
    >
      {{ $t('Suppliers for this product must include minimum one supplier') }}
    </small>

    <l-textarea
      v-model="quote.message"
      name="quote-msg"
      :field="{ label: 'Message', rules: 'required' }"
      class="suppliers-for-this-product__message-box"
    />
  </div>
</template>

<script>
// Components
import LTextarea from '@/views/components/DynamicForm/components/LTextarea.vue'
import {
  BFormCheckbox,
  VBTooltip,
  BTr,
  BTh,
  BThead,
  BTbody,
  BTableSimple,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import AddSuppliersForProduct from './AddSuppliersForProduct/AddSuppliersForProduct.vue'
import TableSkeleton from './TableSkeleton.vue'

import config from '../config'

export default {
  name: 'SuppliersForThisProduct',
  components: {
    BTr,
    BTh,
    BThead,
    BTbody,
    LTextarea,
    BTableSimple,
    BFormCheckbox,
    TableSkeleton,
    AddSuppliersForProduct,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isSuppliersForThisProductIsEmpty: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      onSubmit: false,
      isLoading: false,
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME_QUOTE].quoteForm
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    suppliers() {
      return this.$store.state[this.MODULE_NAME_QUOTE].suppliers
    },
    isThereAnySuppliers() {
      return !this.suppliers.data.supplier.length
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    setSuppliersToRequestForQuote(item) {
      const id = item.supplier_id ? item.supplier_id : item.id
      const suppliersForQuote = this.quote.suppliers_for_quote

      if (suppliersForQuote.includes(id)) {
        this.quote.suppliers_for_quote = suppliersForQuote.filter(quoteID => quoteID !== id)
      } else {
        suppliersForQuote.push(id)
      }
    },
    getProperty(property, suplier) {
      const firstName = suplier.customer?.default_contact.first_name ?? suplier.default_contact.first_name
      const lastName = suplier.customer?.default_contact.last_name ?? suplier.default_contact.last_name
      const companyName = suplier.cust_name_dba ?? suplier.customer.cust_name_dba

      switch (property) {
        case 'fullName':
          return `${firstName} ${lastName}`
        case 'companyName':
          return companyName
        default:
          return ''
      }
    },
    fetchData() {
      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME_QUOTE}/getSuppliers`).then(() => {})
        .catch(err => {
          this.error = err.response?.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t(err.response?.data.message, { module: this.MODULE_NAME_QUOTE }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.isLoading = false
        })
    },
  },
  setup() {
    const MODULE_NAME_QUOTE = 'quote'
    const MODULE_NAME = 'inventories'

    const { tableColumnsCatalog } = config()
    return {
      tableColumnsCatalog,
      MODULE_NAME_QUOTE,
      MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
.suppliers-for-this-product__message-box {
  & textarea {
    min-height: 107px;
  }
}
</style>
<style lang="scss">
.name-cell {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 24px;
}

</style>
