<template>
  <div
    class="d-flex align-items-center mr-1"
    style="gap: 14px"
  >
    <feather-icon
      v-b-tooltip.noninteractive.hover
      icon="LInOutIcon"
      size="24"
      :title="$t('Create Permanent Transfer Order')"
      class="cursor-pointer border-dotted feather-trash-icon d-flex justify-center align-center"
      style="height: 33px; min-width: 33px; padding: 8px"
      @click="gotoNewPage({name: 'transfer-create-by-inventory', params: {id: routeID}}, $event)"
    />
    <feather-icon
      v-b-tooltip.noninteractive.hover
      icon="LCalendarsIcon"
      size="24"
      :title="$t('Calendar')"
      class="cursor-pointer border-dotted feather-trash-icon d-flex justify-center align-center"
      style="height: 33px; min-width: 33px; padding: 8px"
      @click="navigateToCalendar(routeID, $event, 'home-inventory-calendar')"
    />
    <feather-icon
      v-if="canShowTrashList"
      v-b-tooltip.noninteractive.hover
      icon="LTrashOpenIcon"
      size="24"
      :title="$t('Trash List')"
      class="cursor-pointer border-dotted feather-trash-icon defaultIconColor d-flex justify-center align-center"
      style="height: 33px; min-width: 33px; padding: 8px"
      @click="navigateToTrashList($event, 'home-inventory-item-trash-list')"
    />
    <feather-icon
      v-b-tooltip.noninteractive.hover
      icon="LPOIcon"
      :title="$t('Create a RFQ to all registered suppliers')"
      class="cursor-pointer"
      size="37"
      @click="navigateToPo(routeID, $event, 'home-inventory-item-quote')"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'TableActions',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    canShowTrashList: {
      type: Object,
      default: () => ({ action: 'manage', subject: 'all' }),
    },
  },
  computed: {
    routeID() {
      return this.$route.params.id
    },
  },
  methods: {
    navigateToTrashList(e, name) {
      const data = {
        inv_id: this.$route.params.id,
        location: this.$route.query.location,
        current_location: this.$route.query.current_location,
        active: 0,
      }
      this.navigateTo(data, e, name)
    },
    navigateToCalendar({ id }, e, name) {
      this.navigateTo({ inv_id: id }, e, name)
    },
    navigateToPo({ id }, e, name) {
      this.navigateTo({ inv_id: id }, e, name)
    },
    navigateTo(params, e, name) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name,
          params,
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name,
          params,
        })
      }
    },

  },
}
</script>
<style>

</style>
