<template>
  <b-form-group>
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <div class="d-flex align-items-center justify-content-between">
        <label
          v-if="hasLabel"
          :class="checkRequired(field) ? 'inputLabel': 'inputLabelNormal'"
          class="pl-0"
          :for="name"
        >
          {{ $t(field.label) }}
        </label>
        <div class="l-counter d-flex">
          <b-button
            class="btn-mini l-counter__btn-mini"
            :disabled="inventory.amount_to_add.length <= AMOUNT_ADD_MIN_LIMIT"
            @click="incrementAndDecrement('-')"
          >
            <feather-icon
              icon="LMinusIcon"
              size="16"
            />
          </b-button>
          <b-form-input
            :id="name"
            v-model="inventory.amount_to_add.length"
            disabled
            v-bind="getProps(field)"
            class="l-counter__input bg-white"
          />
          <b-button
            class="btn-mini"
            variant="success"
            :disabled="inventory.amount_to_add.length >= AMOUNT_ADD_MAX_LIMIT"
            @click="incrementAndDecrement('+')"
          >
            <feather-icon
              icon="LPlusPureIcon"
              size="16"
              style="stroke:white;"
            />
          </b-button>
        </div>
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormInput, BFormGroup, BButton } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import config from '@/views/main/inventory/inventoryConfig'

export default {
  name: 'LCounterInput',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '0',
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    formName: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  computed: {
    inventory() {
      return this.$store.state[this.moduleName][this.formName]
    },
  },
  methods: {
    incrementAndDecrement(actions) {
      if (actions === '+') this.inventory.amount_to_add.push(JSON.parse(JSON.stringify(this.blankLocation)))
      if (actions === '-') this.inventory.amount_to_add.pop()
    },
  },
  setup() {
    const { AMOUNT_ADD_MIN_LIMIT, AMOUNT_ADD_MAX_LIMIT, blankLocation } = config()

    return {
      blankLocation,
      AMOUNT_ADD_MIN_LIMIT,
      AMOUNT_ADD_MAX_LIMIT,
    }
  },
}
</script>

<style lang="scss">
.l-counter {
  gap: 8px;
  &__input {
    max-width: 100px;
    width: 100%;
    text-align: center;
    font-weight: 700;
  }

  &__btn-mini {
    min-width: 36px;
  }
}
</style>
