<template>
  <div>
    <b-table-simple class="suppliers-for-this-product-table">
      <b-thead>
        <b-tr>
          <b-th
            v-for="{key, label, thStyle} in tableColumnsCatalog"
            :key="key"
            :style="thStyle"
          >
            {{ label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(_, index) in Array(7)"
          :key="index"
          style="height: 40px"
        >
          <b-th>
            <b-skeleton
              width="18px"
              style="margin-bottom: 0"
              class="ml-1"
            />
          </b-th>
          <b-th
            v-for="(_, idx) in Array(6)"
            :key="idx"
          >
            <b-skeleton
              width="80%"
              style="margin-bottom: 0"
            />
          </b-th>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTr,
  BTh,
  BThead,
  BTbody,
  BSkeleton,
  BTableSimple,
} from 'bootstrap-vue'
import config from '../config'

export default {
  name: 'TableSkeleton',
  components: {
    BSkeleton,
    BTr,
    BTh,
    BThead,
    BTbody,
    BTableSimple,
  },
  setup() {
    const { tableColumnsCatalog } = config()

    return {
      tableColumnsCatalog,
    }
  },
}
</script>
