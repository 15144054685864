<template>
  <div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <validation-observer
      ref="refInventoryQuote"
    >
      <amount-request-quote class="mb-1" />
      <suppliers-for-this-product :is-suppliers-for-this-product-is-empty="isSuppliersForThisProductIsEmpty" />
      <portal to="body-footer">
        <div class="d-flex mt-2 pb-1 justify-content-between">
          <div>
            <b-button
              class="cancelBtn font-medium-1 font-weight-bolder px-3"
              variant="outline-primary"
              @click="cancel"
            >
              <feather-icon icon="LCancelIcon" />
              {{ $t('Cancel') }}
            </b-button>
          </div>
          <div
            class="d-flex"
            style="gap: 20px"
          >
            <b-button
              variant="secondary"
              class="saveBtn font-medium-1 font-weight-bolder px-2"
              @click="submit(ACTION_PREPARE)"
            >
              {{ $t('Prepare RFQs') }}
            </b-button>
            <b-button
              variant="success"
              class="saveBtn font-medium-1 font-weight-bolder px-2"
              @click="submit(ACTION_SEND)"
            >
              {{ $t('Send RFQs') }}
            </b-button>
          </div>
        </div>
      </portal>
    </validation-observer>
  </div>
</template>

<script>
// Components
import { ValidationObserver } from 'vee-validate'
import { BButton } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import AmountRequestQuote from './components/AmountRequestQuote.vue'
import SuppliersForThisProduct from './components/SuppliersForThisProduct.vue'
import config from './config'

export default {
  name: 'QuoteView',
  components: {
    BButton,
    ErrorAlert,
    AmountRequestQuote,
    ValidationObserver,
    SuppliersForThisProduct,
  },
  data() {
    return {
      error: {},
      isSuppliersForThisProductIsEmpty: false,
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME_QUOTE].quoteForm
    },
    suppliersForQuote() {
      return this.$store.state[this.MODULE_NAME_QUOTE].quoteForm.suppliers_for_quote
    },
    productID() {
      return this.$store.state[this.MODULE_NAME].inventoryId
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  watch: {
    suppliersForQuote(data) {
      if (data.length) this.isSuppliersForThisProductIsEmpty = false
    },
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'home-inventory-view', query: { location: `${this.warehouse.id}` } })
    },
    submit(action) {
      this.error = {}
      this.onSubmit = true
      const data = this.quote
      if (!data.suppliers_for_quote.length) this.isSuppliersForThisProductIsEmpty = true
      if (data.suppliers_for_quote.length) this.isSuppliersForThisProductIsEmpty = false

      this.$refs.refInventoryQuote.validate().then(success => {
        if (success) {
          if (this.isSuppliersForThisProductIsEmpty) return
          const requiredBy = new Date(data.required_by)

          const mappedData = {
            product: this.productID,
            amount: data.amount_to_add.length,
            supplier_ids: data.suppliers_for_quote,
            required_by: `${requiredBy.getFullYear()}-${requiredBy.getMonth() + 1}-${requiredBy.getDay()}`,
            message: data.message,
            action,
          }

          this.sendNotification(this, mappedData, `${this.MODULE_NAME_QUOTE}/create`)
            .then(() => {
              setTimeout(() => {
                this.$router.push({ name: 'home-orders-purchase-cr-list' })
              }, 0)
            }).catch(err => {
              this.error = err.response?.data
            })
        } else {
          scrollToError(this, this.$refs.refInventoryQuote)
        }
      })
    },
  },
  setup() {
    const MODULE_NAME_QUOTE = 'quote'
    const MODULE_NAME = 'inventories'

    const {
      fields,
      ACTION_SEND,
      ACTION_PREPARE,
    } = config()
    return {
      fields,
      ACTION_SEND,
      ACTION_PREPARE,
      MODULE_NAME,
      MODULE_NAME_QUOTE,
    }
  },
}
</script>
