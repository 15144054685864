import axiosIns from '@/libs/axios'
import { stringify } from 'qs'
// eslint-disable-next-line import/extensions
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

export default function config() {
  const formattedDate = () => {
    const date = {
      fullYear: new Date().getFullYear(),
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
    }
    const { fullYear, day, month } = date
    const secondMonth = month < 10 ? `0${month}` : month
    const secondDay = day < 10 ? `0${day}` : day
    return [`${fullYear}`, `${secondMonth}`, `${secondDay}`].join('')
  }
  const download = (res, { export_type, title }) => {
    const fileBlob = window.URL.createObjectURL(new Blob([res.data]))
    const fileLink = document.createElement('a')
    fileLink.href = fileBlob
    const fileName = export_type || title.toLowerCase().split(' ').join('_')
    fileLink.setAttribute('download', `${fileName}_${formattedDate()}.csv`)
    fileLink.setAttribute('id', 'test-download')
    // fileLink.setAttribute('target', '_blank')
    document.body.appendChild(fileLink)
    fileLink.click()
  }

  const exportDataRequest = (path, params, MODULE_NAME, ctx) => {
    axiosIns({
      url: path,
      params,
      // eslint-disable-next-line no-shadow
      paramsSerializer: params => stringify(params),
      method: 'GET',
      responseType: 'blob',
    }).then(res => {
      download(res, params)
    }).catch(err => {
      console.log(err)
      ctx.$toast({
        component: ToastificationContentVue,
        props: {
          title: i18n.t('Error Uploading This file', {
            module: MODULE_NAME,
          }),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }).finally(() => {
      // eslint-disable-next-line no-param-reassign
      ctx.isExporting = false
      ctx.hide()
    })
  }

  const IMPORT_MODAL_ID = 'csv-file-import-modal'
  const EXPORT_MODAL_ID = 'csv-file-export-modal'

  return {
    download,
    IMPORT_MODAL_ID,
    EXPORT_MODAL_ID,
    exportDataRequest,
  }
}
