var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"16px"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('Amount to send the Request for Quote for'))+" ")]),_c('l-counter-input',{staticClass:"mb-0",attrs:{"has-label":false,"name":"amount-request-quote","field":{ label: 'Amount to Add', options: { readonly: true }, rules: 'required|integer|positive' },"form-name":"quoteForm","module-name":"quote"}}),_c('l-data-picker',{staticClass:"mb-0 amount-request-required-by",attrs:{"name":"required-by","field":{
        label: 'Required by',
        rules: 'required',
        options: {
          config: {
            'is-calendar-icon': true,
          },
        }}},model:{value:(_vm.quote.required_by),callback:function ($$v) {_vm.$set(_vm.quote, "required_by", $$v)},expression:"quote.required_by"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }