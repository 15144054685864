<template>
  <div>
    <feather-icon
      v-b-tooltip.noninteractive.hover
      icon="LAddButtonIcon"
      :title="$t('Add a supplier for this product')"
      class="cursor-pointer"
      size="36"
      @click="showModal"
    />
    <b-modal
      id="select-supplier"
      centered
      hide-header
      body-class="select-supplier-body px-1 py-1"
      footer-class="select-supplier-footer"
      ok-variant="success"
      size="md"
      modal-class="select-supplier"
    >
      <div
        v-if="isLoading"
        class="select-supplier-overlay"
      >
        <b-spinner variant="secondary" />
      </div>
      <div class=" px-1 py-1">
        <h3 class="text-center font-weight-bolder mb-2">
          {{ $t('Please select a supplier') }}
        </h3>
        <div
          class="mb-3"
        >
          <select-supplier
            class="mb-1"
            @selectHandler="selectHandler"
          />
          <small
            v-if="isExistsSupplier"
            class="text-danger"
          >
            {{ $t('Duplicated Supplier') }}
          </small>
        </div>

        <div class="d-flex align-items-center justify-content-between mb-2">
          <label
            for="select-supplier-add-permanently"
            class="mb-0"
          >
            {{ $t('Add Permanently as a supplier for this item') }}
          </label>
          <b-form-checkbox
            id="select-supplier-add-permanently"
            v-model="supplierForm.select_supplier_add_permanently"
            class="mt-0"
          />
        </div>
      </div>
      <template #modal-footer="{ }">
        <b-button
          variant="outline-secondary"
          @click="onClose"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="success"
          :disabled="isDisabled"
          @click="onOk"
        >
          {{ $t('Add') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// Components'
import {
  VBTooltip, BFormCheckbox, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import SelectSupplier from './SelectSupplier.vue'
import config from '../../config'

export default {
  name: 'AddSuppliersForProduct',
  components: {
    BButton,
    BSpinner,
    BFormCheckbox,
    SelectSupplier,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    fetchData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      isExistsSupplier: false,
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME_QUOTE].quoteForm
    },
    supplierForm() {
      return this.$store.state[this.MODULE_NAME_QUOTE].supplierForm
    },
    suppliers() {
      return this.$store.state[this.MODULE_NAME_QUOTE].suppliers
    },
    isDisabled() {
      return this.supplierForm.supplier.length ? this.isExistsSupplier : true
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show('select-supplier')
    },
    hideModal() {
      this.$store.commit(`${this.MODULE_NAME_QUOTE}/SET_SUPPLIER_FORM`, {
        supplier: [],
      })
      this.isExistsSupplier = false
      this.$bvModal.hide('select-supplier')
    },
    onClose() {
      this.supplierForm.supplier = []
      this.hideModal()
    },
    onOk() {
      // this.supplierForm.supplier = []
      const isAddPermanently = this.supplierForm.select_supplier_add_permanently
      const suppliersOfAtTheTable = this.suppliers.data.supplier
      const { supplier } = this.supplierForm

      if (isAddPermanently) {
        this.isLoading = true
        const mergeSuppliers = [...suppliersOfAtTheTable, ...supplier]

        const mapData = mergeSuppliers.map(item => ({
          id: item.supplier_id ?? item.id,
        }))

        this.sendNotification(this, { suppliers: mapData }, `${this.MODULE_NAME_QUOTE}/updateProcurement`).then(() => {})
          .catch(err => {
            this.error = err.response?.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t(err.response?.data.message, { module: this.MODULE_NAME_QUOTE }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.$store.dispatch(`${this.MODULE_NAME_QUOTE}/getSuppliers`)
            this.isLoading = false
            this.fetchData()
            this.hideModal()
          })
      } else {
        suppliersOfAtTheTable.push(...supplier)
        this.hideModal()
      }
      // this.hideModal()
    },
    selectHandler() {
      // eslint-disable-next-line camelcase
      const suppliersIdsOfAtTheTable = this.suppliers.data.supplier.map(supplier => (supplier.customer ? supplier.customer.id : supplier.id))
      const { supplier } = this.supplierForm
      const getSelectedSupplierId = supplier[0].id
      console.log(suppliersIdsOfAtTheTable)
      this.isExistsSupplier = suppliersIdsOfAtTheTable.includes(getSelectedSupplierId)
    },
  },
  setup() {
    const MODULE_NAME_QUOTE = 'quote'
    const MODULE_NAME = 'inventories'

    const { tableColumnsCatalog } = config()
    return {
      tableColumnsCatalog,
      MODULE_NAME_QUOTE,
      MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
.select-supplier {
  & .modal-footer {
    border-top: none;
    padding: 0 12px 12px 12px !important;
    display: flex;
    justify-content: space-between;

    & .btn {
      margin: 0;
      min-width: 95px;
    }
  }

  &-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 123%;
    z-index: 1;
    opacity: 0.5;
    border-radius: 4px;
    background-color: #646464;
  }
}
</style>
