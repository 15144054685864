<template>
  <div>
    <div
      class="d-flex align-items-center"
      style="gap: 16px"
    >
      <div>
        {{ $t('Amount to send the Request for Quote for') }}
      </div>
      <l-counter-input
        :has-label="false"
        name="amount-request-quote"
        :field="{ label: 'Amount to Add', options: { readonly: true }, rules: 'required|integer|positive' }"
        class="mb-0"
        form-name="quoteForm"
        module-name="quote"
      />
      <l-data-picker
        v-model="quote.required_by"
        class="mb-0 amount-request-required-by"
        name="required-by"
        :field="{
          label: 'Required by',
          rules: 'required',
          options: {
            config: {
              'is-calendar-icon': true,
            },
          }}"
      />
    </div>

  </div>
</template>

<script>
// Components
import LDataPicker from '@/views/components/DynamicForm/components/LFlatPicker.vue'
import LCounterInput from '../../components/LCounterInput.vue'

export default {
  name: 'AmountRequestQuote',
  components: {
    LDataPicker,
    LCounterInput,
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quoteForm
    },
  },
  setup() {
    const MODULE_NAME = 'quote'

    return {
      MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
.amount-request-required-by {
  position: relative;
  width: 250px;

  & label {
    display: none !important;
  }

  & .flatpickr-input--custom-style {
    background-color: #fff;
    border-radius: 5px;
  }

  & small {
    position: absolute;
  }
}
</style>
