export default function config() {
  const ACTION_PREPARE = 'prepare'
  const ACTION_SEND = 'send'

  // Table Handlers
  const tableColumnsCatalog = [
    {
      key: 'include',
      label: 'Include',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Company Name',
      thStyle: { width: '35%' },
    },
    {
      key: 'created_by',
      label: 'Contact',
      thStyle: { width: '13%' },
    },
    {
      key: 'last_cost_per_item',
      label: 'Last Cost per Item',
      thStyle: { width: '12%' },
    },
    {
      key: 'last_supplied',
      label: 'Last Supplied',
      thStyle: { width: '10%' },
    },
    {
      key: 'p_o',
      label: 'PO #',
      thStyle: { width: '10%' },
    },
    {
      key: 'last_amt_purchased',
      label: 'Last Amt Purchased',
      thStyle: { width: '15%' },
    },
  ]

  const fields = {
    supplier_ids: {
      label: 'Suppliers for this product',
    },
    amount: {
      label: 'Amount to send the Request for Quote',
    },
    required_by: {
      label: 'Required By',
    },
    message: {
      label: 'Message',
    },
  }

  return {
    fields,
    ACTION_SEND,
    ACTION_PREPARE,
    tableColumnsCatalog,
  }
}
