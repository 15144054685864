<template>
  <b-modal
    :id="IMPORT_MODAL_ID"
    centered
    hide-header
    body-class="customer-list-file-upload_download-modal-body"
    footer-class="customer-list-file-upload_download-modal-footer"
    ok-variant="success"
    ok-title="UPLOAD"
    ok-only
    :ok-disabled="!chosenFiles || isUploading"
    modal-class="customer-list-file-upload_download"
    @ok="uploadFile"
    @hide="onHide"
  >
    <div class="d-flex justify-content-end ">
      <b-button
        size="sm"
        @click="hideModalOnCloseButton"
      >
        <feather-icon
          icon="XIcon"
          size="22"
        />
      </b-button>
    </div>
    <div class="text-center font-medium-5 font-weight-bolder pb-2">
      {{ $t('File Import') }}
      <span class="text-warning">{{ title }}</span>
    </div>
    <div class="mb-2 text-center">
      {{ $t('If you haven’t, please download this template to create a [encoding selected on organization] encoded file') }}
    </div>
    <div class="d-flex justify-content-center mb-2">
      <b-button
        style="width: 290px; height: 40px"
        class="btn download-template options-btn text-white text-uppercase px-4 d-flex justify-content-center align-items-center"
        @click="exportButtonHandler(exportPathTemplate)"
      >
        <b-spinner
          v-if="isExporting"
          small
        />
        <span v-else>{{ $t('Download template') }}</span>
      </b-button>
    </div>
    <div class="mb-2">
      {{ $t("And drop here") }}:
    </div>
    <div
      v-if="!!error.length"
      class="mb-1"
    >
      <div class="d-flex justify-content-end mb-1">
        <b-button
          variant="danger"
          size="sm"
          @click="clearErrors"
        >
          {{ $t('Clear Errors') }}
        </b-button>
      </div>
      <b-list-group
        v-for="(er, index) in error"
        :key="index"
      >
        <b-list-group-item
          v-for="[key, val] in Object.entries(er)"
          :key="key"
          class="b-list-group-item-custom text-danger"
        >
          <div style="line-height: 1">
            {{ key }}
          </div>
          <span
            v-for="[keyl, valEl] in Object.entries(val)"
            :key="keyl"
            class="pl-5"
          >
            <span
              v-for="(valElItem, i) in valEl"
              :key="i"
            >{{ valElItem }}</span>
          </span>
        </b-list-group-item>
      </b-list-group>

    </div>
    <div class="position-relative">
      <l-file-upload-c-s-v
        ref="refCSVFileUpload"
        v-model="chosenFiles"
        :accept-extension-array="['.csv']"
      />
      <b-overlay
        :show="isUploading"
        class="position-absolute"
        style="width: 100%; height: 100%; top: 0; left: 0;"
        :style="{zIndex: isUploading ? '100': '-1',}"
        rounded="sm"
      />
    </div>
  </b-modal>
</template>

<script>
import {
  BButton, BOverlay, BSpinner, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import LFileUploadCSV from '@/views/main/customers/components/LFileUploadCSV.vue'
import jsonToFormData from '@/libs/jsonToFormData'
// eslint-disable-next-line import/no-named-as-default
import config from './config'

export default {
  name: 'ImportCSV',
  components: {
    BButton,
    BOverlay,
    BSpinner,
    BListGroup,
    BListGroupItem,
    LFileUploadCSV,
  },
  props: {
    moduleName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExporting: false,
      chosenFiles: null,
      isUploading: false,
      error: [],
    }
  },
  computed: {
    title() {
      return this.$store.state[this.moduleName].import.title
    },
    import() {
      return this.$store.state[this.moduleName].import
    },
    exportPathTemplate() {
      return this.$store.state[this.moduleName].exportPathTemplate
    },
  },
  methods: {
    hide() { this.$bvModal.hide(this.IMPORT_MODAL_ID) },
    show() { this.$bvModal.show(this.IMPORT_MODAL_ID) },
    mapDataObject() {
      return {
        file: this.chosenFiles,
      }
    },
    uploadFile(bvModalEvent) {
      bvModalEvent.preventDefault()
      const formData = jsonToFormData({ file: this.chosenFiles })
      this.postData(formData)
    },
    clear() {
      this.$refs.refCSVFileUpload.fileName = null
      this.$refs.refCSVFileUpload.clearInputValue()
      this.chosenFiles = null
    },
    postData(formData) {
      this.isUploading = true
      // eslint-disable-next-line camelcase
      const { actionType: import_type } = this.import

      this.sendNotification(this, { formData, import_type }, `${this.moduleName}/importCSV`, 'CSV Uploaded Successfully').then(() => {
        this.error = null
        this.clear()
        this.hideModalOnCloseButton()
        this.$emit('refetchTable')
      })
        .catch(err => {
          this.error = [err.response?.data.errors]
          this.clear()
        })
        .finally(() => {
          this.isUploading = false
        })
    },
    onHide(evt) {
      if (evt.trigger === 'backdrop') {
        this.hideModalOnCloseButton()
      }
    },
    clearErrors() {
      this.error = []
    },
    hideModalOnCloseButton() {
      this.hide()
      this.clearErrors()
    },
    exportButtonHandler(path) {
      this.isExporting = true
      // eslint-disable-next-line camelcase
      const { actionType: export_type } = this.import
      this.exportDataRequest(path, { export_type }, this.moduleName, this)
    },
  },
  setup() {
    const { download, exportDataRequest, IMPORT_MODAL_ID } = config()

    return {
      download,
      IMPORT_MODAL_ID,
      exportDataRequest,
    }
  },
}
</script>
